import AdminPanel from '../../layout/AdminPanel'
import React, {useContext, useEffect, useState} from 'react'
import {UserContext} from '../../components/UserContext'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/client'
import {Chart} from 'primereact/chart'
import {Form, Formik} from 'formik'
import ComboEditor from '../../components/editors/ComboEditor'
import {Button} from 'primereact/button'
import {getEnumValue} from '../EnumUtils'
import {BankjegyContext} from '../../components/BankjegyContext'
import {LIST_SOROZAT} from '../../layout/Menu'
import DateEditor from '../../components/editors/DateEditor'
import {formatDateForSubmit} from '../Common'
import * as Yup from 'yup'
import {ProgressBar} from 'primereact/progressbar'

const LIST_ARVALTOZAS = gql`
    query ListArvaltozas($penznem: Penznem!, $sorozatId: Long, $startDate:Date, $minoseg:Minoseg) {
        listArvaltozas(penznem: $penznem, sorozatId:$sorozatId, startDate:$startDate, minoseg:$minoseg) {
            name
            arvaltozasChartData {
                labels
                datasets {
                    label
                    data
                    borderColor:color
                }
            }
        }
    }
`

const ArvaltozasOsszesitesPage = () => {
	const [data, setData] = useState([])
	const userContext = useContext(UserContext)
	let [possibleSorozat, setPossibleSorozat] = useState([])
	let [possiblePenznem, setPossiblePenznem] = useState([])
	let [loading, setLoading] = useState(false)

	useEffect(() => {
		let res = [{label: 'Válasszon!', value: null}]
		userContext.possiblePenznem.forEach((value, index) => (
			res = [...res, {label: getEnumValue('Penznem', value), value: value}]
		))
		setPossiblePenznem(res)

	}, [userContext.possiblePenznem])

	const listSorozatQuery = useQuery(LIST_SOROZAT, {
		skip: true,
	})

	const queryListArvaltozas = useQuery(LIST_ARVALTOZAS, {
		variables: {penznem: 'FORINT'},
		skip: true
	})
	const [chartOptions, setChartOptions] = useState({})
	useEffect(() => {
		const documentStyle = getComputedStyle(document.documentElement)
		const data = {
			labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
			datasets: [
				{
					label: 'First Dataset',
					data: [65, 59, 80, 81, 56, 55, 40],
					fill: false,
					borderColor: documentStyle.getPropertyValue('--blue-500'),
					tension: 0.4
				},
				{
					label: 'Second Dataset',
					data: [28, 48, 40, 19, 86, 27, 90],
					fill: false,
					borderColor: documentStyle.getPropertyValue('--pink-500'),
					tension: 0.4
				}
			]
		}
		const options = {
			series: [{
				name: 'Adatok',
				data: data
			}],
			lines: {
				fill: false,
				strokeWidth: 2,
				stroke: '#007bff'
			}
		}

		setChartOptions(options)
	}, [])

	const initialValues = {
		penznem: null,
		sorozatId: null,
		kezdoDatum:null
	}

	const submit = (values) => {
		setLoading(true)
		if (values.penznem) {
			queryListArvaltozas.refetch({
				penznem: values.penznem,
				sorozatId: values.sorozat,
				startDate: values.kezdoDatum ? formatDateForSubmit(values.kezdoDatum) : null
			}).then(({data})=> {
				if (data?.listArvaltozas) {
					setData(data.listArvaltozas)
				}
				setLoading(false)
			}).finally(()=>{
				setLoading(false)
			})
		}
	}
	return <AdminPanel
		className="arvaltozas-osszesites-content"
		stretch
		label="Bankjegy árváltozás statisztika"
	>
		<div>
			{userContext.loggedInUser.szerep !== 'ADMIN' || !userContext.loggedInUser.superuser ?
				<div
					className="alert alert-warning"
					role="alert"
				>Nincs megfelelő jogosultsága!</div> :
				<>
					<Formik

						initialValues={initialValues}
						onSubmit={submit}
						validationSchema={Yup.object({
						penznem: Yup.string().nullable().required('A mező kitöltése kötelező')
					})}
					>
						{({setFieldValue, values, errors, isSubmitting}) => (
							<Form className='mb-5'>
								<div className="row">
									<div className="col-12 col-sm-6 mb-3">
										<ComboEditor
											fieldLabel="Pénznem"
											fieldName="penznem"
											possibleValues={possiblePenznem}
											onChange={(value) => {
												setFieldValue('sorozat', null)
												setPossibleSorozat([])
												if (value)
												{
													listSorozatQuery?.refetch({penznem: value})?.then((({data})=>{
														const sorozatList = data.listSorozatByPenznem
														let res = [{label: 'Válasszon!', value: null}]
														sorozatList.forEach((value, index) => (
															res = [...res, {label: value.nev, value: value.id}]
														))
														setPossibleSorozat(res)
													}))
												}
											}}
										/>
									</div>
									<div className="col-12 col-sm-6 mb-3">
										<ComboEditor
											fieldLabel="Sorozat"
											fieldName="sorozat"
											possibleValues={possibleSorozat}
											emptyMessage='Először válassza ki a pénznemet!'
										/>
									</div>
									<div className="col-12 col-sm-6 mb-3">
										<DateEditor
											fieldLabel="Kezdő dátum"
											fieldName="kezdoDatum"
											showButtonBar
										/>
									</div>
								</div>
								<div className="buttons">
									<Button
										className="brown-button"
										label="Keresés"
										type="submit"
									/>
								</div>
							</Form>
						)}
					</Formik>
					<>
					{loading && <ProgressBar className="mt-3" mode="indeterminate"/>}
					{!loading && data.map((arvaltozasChartOption, idx) => {
						return <div key={`chart_${idx}`}>
							<h4 className="mt-3">{arvaltozasChartOption.name}</h4>
							<Chart
								className="mb-5"
								type="line"
								data={Object.assign({}, arvaltozasChartOption.arvaltozasChartData)}
								options={chartOptions}
							/>

						</div>
					})}</>
				</>

			}</div>
	</AdminPanel>
}

export default ArvaltozasOsszesitesPage