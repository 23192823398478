import React, {useContext, useEffect, useRef} from 'react'
import gql from 'graphql-tag'
import {useMutation, useQuery} from '@apollo/client'
import Breadcrumb from '../../layout/Breadcrumb'
import {formatCurrency, formatDate} from '../Common'
import imgMoney from '../../images/money-128.png'
import imgPrev from '../../images/previous-32.png'
import imgNext from '../../images/next-32.png'
import {BankjegyContext} from '../../components/BankjegyContext'
import {Link} from 'react-router-dom'
import UserHeaderPanel from '../user/UserHeaderPanel'
import {preventImageDownload} from './ListPage'
import {ProgressBar} from 'primereact/progressbar'
import {createErrorMessage} from '../user/LoginPage'
import {Messages} from 'primereact/messages'
import EditBankjegyForm from './EditBankjegyForm'
import imgEdit16 from '../../images/edit-w-16.png'
import {UserContext} from '../../components/UserContext'
import useViewport from '../useViewport'
import CollectorPanel from './CollectorPanel'
import {Galleria} from 'primereact/galleria'
import {cloneDeep} from '@apollo/client/utilities'
import ArvaltozasPanel from './ArvaltozasPanel'

export const GET_BANKJEGY = gql`
    query GetBankjegyById($id: ID!) {
        getBankjegyById(id: $id) {
            id
            nevertek
            nevertekEgyseg
            penznem
            sorozat {
                id
                nev
            }
            meret
            tipus
            megjegyzes
            keltezesDatuma
            keltezesEve
            kibocsajtasDatuma
            kibocsajtasEve
            bevonasDatuma
            bevonasEve
            atvaltasDatuma
            atvaltasEve
            forgalomban
            ertekUnc
            ertekEf
            ertekVf
            sorrend
            statusz
            tobbUncErtek
            bankjegyBetujelek {
				id
                betujel
                ertek
            }
            elooldal {
                leiras
                belyegKep
                eredetiKep
                megjelenoKep
            }
            hatoldal {
                leiras
                belyegKep
                eredetiKep
                megjelenoKep
            }
        }
    }
`

const BANKJEGY_MEGTEKINTES = gql`
    mutation BankjegyMegtekintes($bankjegyId: Long!) {
        bankjegyMegtekintes(bankjegyId: $bankjegyId)
    }
`

export const LabelValue = ({label, value, className, children}) => {
	return (
		<div className={`d-flex label-value-comp col-12 flex-wrap ${className ? className : ''}`}>
			<label htmlFor="labelValue" className="col-5 col-md-6 col-form-label px-3">{label}:</label>
			<div className="col-7 col-md-6 pe-3">
				{children ? children :
					(value && <span key={`input_${value}`} className="form-control-plaintext">{value}</span>)}
			</div>
		</div>
	)
}

export const ImageWithModal = ({image, toggleModal}) => {
	return (
		<div className={`img-wrapper flex-grow-1 text-center ${image ? '' : 'empty-image'}`}>
			{image ? <img className="cursor-pointer" alt="bankjegy"
				src={`/kep/${image}`}
				onClick={toggleModal}
			/> : <img src={imgMoney} alt="empty bankjegy"/>}
		</div>
	)
}

const Bankjegy = (value) => {
	let bankjegy = cloneDeep(value.value)
	let elooldal = value.value.elooldal
	let hatoldal = value.value.hatoldal

	const images = [{source: `/kep/${bankjegy.elooldal.eredetiKep}`}, {source: `/kep/${bankjegy.hatoldal.eredetiKep}`}]

	const bankjegyContext = useContext(BankjegyContext)
	const userContext = useContext(UserContext)
	let prevBankjegy = bankjegyContext.prevBankjegy(bankjegy.id)
	let nextBankjegy = bankjegyContext.nextBankjegy(bankjegy.id)
	let prevLinkTo = ''
	let nextLinkTo = ''
	if (prevBankjegy != null) {
		prevLinkTo = {pathname: `/bankjegy/${prevBankjegy.sorozatId}/${prevBankjegy.bankjegyId}`}
	}
	if (nextBankjegy != null) {
		nextLinkTo = {pathname: `/bankjegy/${nextBankjegy.sorozatId}/${nextBankjegy.bankjegyId}`}
	}
	let editLinkTo = {pathname: `/bankjegy_szerkesztes/${bankjegy.sorozat.id}/${bankjegy.id}`}
	let clickLink = () => {
		bankjegyContext.setSelectedBankjegyId(bankjegy.id)
	}
	const [bankjegyMegtekintes] = useMutation(BANKJEGY_MEGTEKINTES, {variables: {bankjegyId: bankjegy.id}})

	useEffect(() => {
		if (bankjegyContext.locationChanged) {
			bankjegyMegtekintes().then()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bankjegyMegtekintes])
	const galeriaRef = useRef(null)
	return (
		<>
			<div className="navigate-wrapper row mx-0 mx-md-1">
				<>
					<div className="navigate-buttons col">
						{prevBankjegy != null && <span className="prev-link">
							<Link to={prevLinkTo}>
								<img src={imgPrev} alt="Előző"/>
							</Link></span>}
						{nextBankjegy != null && <span className="next-link">
							<Link to={nextLinkTo}>
								<img src={imgNext} alt="Következő"/>
							</Link></span>}
					</div>
					{prevBankjegy != null && nextBankjegy != null &&
					<div className="col-auto align-self-center pr-0">
						{`${bankjegyContext.currentIdx} / ${bankjegyContext.length}`}
					</div>
					}
				</>
				<div className="col-auto align-self-center pl-0">
					{userContext.loggedInUser.szerep === 'ADMIN' && bankjegy.statusz === 'AKTIV' && <Link className="brown-button ml-2" to={editLinkTo} onClick={() => {
						clickLink()
						bankjegyContext.setEditBankjegyFromPage('bankjegyPage')
					}}>
						<div className="d-inline-flex align-items-center">
							<img src={imgEdit16} alt="edit-bankjegy"/>
						</div>
					</Link>}
				</div>
			</div>
			{bankjegy.statusz === 'INAKTIV' &&
			<div className="alert alert-danger mx-2" role="alert">TÖRÖLT BANKJEGY</div>}
			<div className="row flex-wrap mx-0 mx-md-1
			">
				<Galleria
					ref={galeriaRef}
					value={images}
					showThumbnails={false}
					style={{maxWidth: '100%'}}
					circular
					fullScreen
					showItemNavigators
					item={(item) => {
						return <img alt='bankjegyImage' src={item.source} style={{width: '100%', display: 'block'}}/>
					}}
					onShow={() => {
						preventImageDownload()
					}}
				/>
				<div className="col-xl-7">
					<div className="mb-3">
						<ImageWithModal image={bankjegy.elooldal.megjelenoKep} toggleModal={() => galeriaRef.current.show()}/>
					</div>
					<div className="mb-3">
						<ImageWithModal image={bankjegy.hatoldal.megjelenoKep} toggleModal={() => galeriaRef.current.show()}/>
					</div>
				</div>
				<div className="col-xl-5">
					<div className="d-flex flex-wrap bankjegy-desc">
						<div className="col-12 col-lg-6 col-xl-12 pe-lg-2 pe-xl-0">
							<div>
								{userContext.loggedInUser.szerep === 'ADMIN' && <LabelValue label="Sorrend" className="sorrend mb-3" value={bankjegy.sorrend}/>}
							</div>
							<div>
								<LabelValue label="Névérték" value={formatCurrency(bankjegy.nevertek, bankjegy.nevertekEgyseg, bankjegy.penznem)}/>
								<LabelValue label="Méret" value={`${bankjegy.meret ? (bankjegy.meret + ' mm') : ''}`}/>
								<LabelValue label="Típus" value={bankjegy.tipus}/>
								<LabelValue label="Megjegyzés" value={bankjegy.megjegyzes}/>
								{bankjegy.keltezesEve && !bankjegy.keltezesDatuma && <LabelValue label="Keltezés éve" value={bankjegy.keltezesEve}/>}
								{(!bankjegy.keltezesEve || bankjegy.keltezesDatuma) && <LabelValue label="Keltezés dátuma" value={formatDate(bankjegy.keltezesDatuma)}/>}
								{bankjegy.kibocsajtasEve && !bankjegy.kibocsajtasDatuma && <LabelValue label="Kibocsátás éve" value={bankjegy.kibocsajtasEve}/>}
								{(!bankjegy.kibocsajtasEve || bankjegy.kibocsajtasDatuma) && <LabelValue label="Kibocsátás dátuma" value={formatDate(bankjegy.kibocsajtasDatuma)}/>}
							</div>
						</div>
						<div className="col-12 col-lg-6 col-xl-12 ps-lg-2 ps-xl-0">
							{bankjegy.bevonasEve && !bankjegy.bevonasDatuma && <LabelValue label="Bevonás éve" value={bankjegy.bevonasEve}/>}
							{(!bankjegy.bevonasEve || bankjegy.bevonasDatuma) && <LabelValue label="Bevonás dátuma" value={
								bankjegy.bevonasDatuma ? formatDate(bankjegy.bevonasDatuma) : (
									bankjegy.forgalomban === true ? 'forgalomban' : ''
								)}
							/>}
							{bankjegy.atvaltasEve && !bankjegy.atvaltasDatuma && <LabelValue label="Átváltás éve" value={bankjegy.atvaltasEve}/>}
							{(!bankjegy.atvaltasEve || bankjegy.atvaltasDatuma) && <LabelValue label="Átváltás dátuma" value={formatDate(bankjegy.atvaltasDatuma)}/>}
							{bankjegy.tobbUncErtek ?
								<>
									{bankjegy.bankjegyBetujelek && bankjegy.bankjegyBetujelek
										.sort((v1, v2) => ((v1.betujel > v2.betujel) ? 1 : (v2.betujel > v1.betujel) ? -1 : 0))
										.map((b, idx) => (
										<LabelValue key={`${b.betujel}${idx}`} label={`Érték UNC (${b.betujel})`} value={formatCurrency(b.ertek)}/>
									))}
								</> :
								<LabelValue label="Érték UNC" value={formatCurrency(bankjegy.ertekUnc)}/>
							}

							<LabelValue label="Érték EF" value={formatCurrency(bankjegy.ertekEf)}/>
							<LabelValue label="Érték VF" value={formatCurrency(bankjegy.ertekVf)}/>
							<LabelValue label="Előoldal leírás" value={elooldal.leiras}/>
							<LabelValue label="Hátoldal leírás" value={hatoldal.leiras}/>
						</div>
					</div>
				</div>
			</div>
			{userContext.loggedInUser.gyujto &&
				<CollectorPanel bankjegy={bankjegy}/>
			}
			{userContext.loggedInUser.szerep === 'ADMIN' &&
				<ArvaltozasPanel bankjegyId={bankjegy.id}/>
			}
		</>
	)
}

export const BankjegyPage = ({match}) => {

	const editBankjegy = match.url.includes('bankjegy_szerkesztes')
	const messageRef = useRef(null)
	useEffect(() => {
		preventImageDownload()
	})
	const isNewBankjegy = !match.params.id
	const queryGetBankjegy = useQuery(GET_BANKJEGY, {skip: isNewBankjegy, variables: {id: match.params.id}, fetchPolicy: 'network-only'})
	const viewport = useViewport()
	return (
		<>
			{['md', 'lg', 'xl'].includes(viewport) && <UserHeaderPanel theme="white" className="py-2"/>}
			<div className="bankjegy-page-content m-3">
				<Breadcrumb needBackButton={true}/>

				{queryGetBankjegy.loading && <ProgressBar className="mt-3" mode="indeterminate"/>}
				{queryGetBankjegy.error &&
				<>
					{createErrorMessage(queryGetBankjegy.error, 'Sikertelen betöltés!', messageRef, 20000)}
					<Messages className="mt-3" ref={messageRef}/>
				</>}
				{!queryGetBankjegy.loading && !queryGetBankjegy.error && queryGetBankjegy.data &&
				(editBankjegy ?
					<EditBankjegyForm value={queryGetBankjegy.data.getBankjegyById}/> :
					<Bankjegy value={queryGetBankjegy.data.getBankjegyById}/>)
				}
				{isNewBankjegy &&
				<EditBankjegyForm/>}
			</div>
		</>
	)
}

export default BankjegyPage