import React, {useContext, useEffect, useRef, useState} from 'react'
import gql from 'graphql-tag'
import imgMoney from '../../images/money-128.png'
import {Link, withRouter} from 'react-router-dom'
import {formatCurrency, formatDate} from '../Common'
import ModalImage from 'react-modal-image'
import Breadcrumb from '../../layout/Breadcrumb'
import ScrollUpButton from 'react-scroll-up-button'
import imgScrollToTop48 from '../../images/arrowhead-48.png'
import imgEdit16 from '../../images/edit-w-16.png'
import imgDelete16 from '../../images/trash-w-16.png'
import imgNew16 from '../../images/new-w-16.png'
import {BankjegyContext} from '../../components/BankjegyContext'
import UserHeaderPanel from '../user/UserHeaderPanel'
import $ from 'jquery'
import {getEnumValue} from '../EnumUtils'
import {UserContext} from '../../components/UserContext'
import {Button} from 'primereact/button'
import {ProgressBar} from 'primereact/progressbar'
import {createErrorMessage} from '../user/LoginPage'
import {Messages} from 'primereact/messages'
import {Dropdown} from 'primereact/dropdown'
import useViewport from '../useViewport'
import {useMutation, useQuery} from '@apollo/client'
import {Tooltip} from 'bootstrap'
import {Dialog} from 'primereact/dialog'

export const FIND_BANKJEGY = gql`
    query FindBankjegy($penznem: Penznem, $sorozatId: ID, $searchText: String, $sorrend: Sorrend!, $sorrendIrany: SorrendIrany!) {
        findBankjegy(penznem: $penznem, sorozatId: $sorozatId, searchText: $searchText, sorrend: $sorrend, sorrendIrany: $sorrendIrany) {
            id
            sorrend
            forrasAzonosito
            penznem
            tipus
            nevertek
            nevertekEgyseg
            keltezesDatuma
            keltezesEve
            kibocsajtasDatuma
            kibocsajtasEve
            forgalomban
            kepAzonosito
            statusz
            tobbUncErtek
            megjegyzes
            bankjegyMegjegyzes {
                darabszamEf
                darabszamVf
                darabszamUnc
                bankjegyMegjegyzesBetujelek {
                    betujel
                    darabszam
                }
            }
            elooldal {
                megjelenoKep
                eredetiKep
                belyegKep
            }
            hatoldal {
                megjelenoKep
                eredetiKep
                belyegKep
            }
            sorozat {
                id
            }
        }
    }
`
const DELETE_BANKJEGY = gql`
    mutation DeleteBankjegy($bankjegyId: Long!) {
        deleteBankjegy(bankjegyId: $bankjegyId) {
            id
        }
    }
`

export const ScrScrollUpButton = () => {
	return <ScrollUpButton
		ContainerClassName="scroll-up-button-container"
		TransitionClassName="scroll-up-button-transition"
	>
		<img src={imgScrollToTop48} alt="scroll to up"/>
	</ScrollUpButton>
}

const LabelValue = ({label, value}) => {
	return (
		<div className="row label-value-comp">
			<label htmlFor="labelValue" className="col-sm-5 col-form-label text-nowrap">{label}:</label>
			<div className="col-sm-7">
				{value && <span key={`input_${value}`} className="form-control-plaintext">{value}</span>}
			</div>
		</div>
	)
}

export const Image = ({tinyImage, hugeImage, className}) => {
	return (
		<div className={`img-wrapper flex-grow-1 text-center ${tinyImage ? '' : 'empty-image'} ${className ? className : ''}`}>
			{tinyImage ? <ModalImage
				hideDownload
				small={`/kep/${tinyImage}`}
				large={`/kep/${hugeImage}`}
			/> : <img src={imgMoney} alt="empty bankjegy"/>}
		</div>
	)
}

const BankjegyList = ({bankjegyContext, location, values, modalIsOpen, setModalIsOpen, deleteBankjegy}) => {
	let params = location ? new URLSearchParams(location.search) : ''
	const userContext = useContext(UserContext)
	useEffect(() => {
		if (bankjegyContext.mainInnerRef.current !== null) {
			bankjegyContext.mainInnerRef.current.scrollTop = bankjegyContext.listPageScrollPosition
		}
	})
	const onModalOpen = () => {
		setModalIsOpen(true)
	}
	const onModalClose = () => {
		setModalIsOpen(false)
	}
	return (<div className="bankjegy-list">
			<Dialog
				header="Törlés"
				visible={modalIsOpen}
				onHide={onModalClose}>
				<div className="">
					<div>Biztosan törölni akarja a kiválasztott elemet?</div>
					<div className="buttons mt-2">
						<Button label="Igen" className="red-button" onClick={() => {
							deleteBankjegy()
						}
						}/>
						<Button onClick={onModalClose} label="Nem" className="green-button"/>
					</div>
				</div>
			</Dialog>
			<div className="d-flex flex-wrap">
				{values.map((bankjegy) => {
					sessionStorage.setItem('bcSearchText', params.get('searchText') ? params.get('searchText') : '') //active menuhoz kell eltárolni
					let linkTo = {pathname: `/bankjegy/${bankjegy.sorozat.id}/${bankjegy.id}`}
					let editLinkTo = {pathname: `/bankjegy_szerkesztes/${bankjegy.sorozat.id}/${bankjegy.id}`}
					let clickLink = () => {
						bankjegyContext.setBankjegyList(values)
						bankjegyContext.setSelectedBankjegyId(bankjegy.id)
					}
					let pieceUnc = 0
					if (bankjegy.tobbUncErtek && bankjegy.bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek && bankjegy.bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek.length > 0) {
						bankjegy.bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek.forEach(b => {
							if (b.darabszam && b.darabszam > 0) {
								pieceUnc += b.darabszam
							}
						})
					} else {
						pieceUnc = bankjegy.bankjegyMegjegyzes.darabszamUnc
					}
					return <div key={`bankjegyitem_${bankjegy.id}`}
						className={`card bankjegy-item m-3 ${bankjegyContext.selectedBankjegyId === bankjegy.id ? 'selected' : ''}`}>
						{userContext.loggedInUser.szerep === 'ADMIN' && <div className="sorrend">{bankjegy.sorrend}</div>}
						<Link to={linkTo} onClick={clickLink}>
							<Image tinyImage={bankjegy.elooldal.belyegKep} hugeImage={bankjegy.elooldal.megjelenoKep}/>
						</Link>
						<div className="card-body position-relative">
							{userContext.loggedInUser.gyujto && <PieceIndicatorPanel pieceUnc={pieceUnc} pieceVf={bankjegy.bankjegyMegjegyzes.darabszamVf}
								pieceEf={bankjegy.bankjegyMegjegyzes.darabszamEf}/>}
							<h5 className="card-title">{getEnumValue('Penznem', bankjegy.penznem)}</h5>
							<div className="card-text">
								<LabelValue label="Típus" value={bankjegy.tipus}/>
								<LabelValue label="Névérték" value={formatCurrency(bankjegy.nevertek, bankjegy.nevertekEgyseg, bankjegy.penznem)}/>
								<LabelValue label="Keltezés" value={bankjegy.keltezesDatuma ? formatDate(bankjegy.keltezesDatuma) : bankjegy.keltezesEve}/>
								<LabelValue label="Kibocsátás"
									value={bankjegy.kibocsajtasDatuma ? formatDate(bankjegy.kibocsajtasDatuma) : bankjegy.kibocsajtasEve}/>
							</div>
							<div
								className={`mt-3 text-right d-flex ${userContext.loggedInUser.szerep === 'ADMIN' ? 'justify-content-between' : 'justify-content-end'}`}>
								{userContext.loggedInUser.szerep === 'ADMIN' && <div>
									<Link className="brown-button" to={editLinkTo} onClick={(e) => {
										clickLink(e)
										bankjegyContext.setEditBankjegyFromPage('listPage')
									}}>
										<div className="d-inline-flex align-items-center">
											<img src={imgEdit16} alt="edit-bankjegy"/>
										</div>
									</Link>
									<button className="red-button ms-1 cursor-pointer" onClick={() => {
										bankjegyContext.setSelectedBankjegyId(bankjegy.id)
										onModalOpen()
									}}>
										<div className="d-inline-flex align-items-center">
											<img src={imgDelete16} alt="delete-bankjegy"/>
										</div>
									</button>
								</div>}
								<Link className="green-button" to={linkTo} onClick={clickLink}>Részletek</Link>
							</div>
							{bankjegy.megjegyzes &&
								<>
									<hr/>
									<div style={{whiteSpace: 'pre-wrap'}} className="overflow-visible, text-wrap, text-break mt-3">{bankjegy.megjegyzes}</div>
								</>
							}
						</div>
					</div>
				})}
			</div>
		</div>
	)
}

export const preventImageDownload = () => {
	$('img').bind('contextmenu', function (e) {
		e.preventDefault()
		return false
	})
}

const PieceIndicator = ({piece, tooltip}) => {
	const hasBankjegy = piece && piece > 0
	const indicatorRef = useRef()
	useEffect(() => {
		if (indicatorRef) {
			new Tooltip(indicatorRef.current)
		}
	}, [indicatorRef])
	return <span
		ref={indicatorRef}
		className={hasBankjegy ? 'green-indicator' : 'red-indicator'}
		data-bs-toggle="tooltip" title={tooltip + ': ' + (hasBankjegy ? piece : '0')}>
		{hasBankjegy ? piece : ''}
	</span>
}

const PieceIndicatorPanel = ({pieceUnc, pieceVf, pieceEf}) => {
	return <div className="piece-indicator-panel d-flex">
		<PieceIndicator piece={pieceUnc} tooltip="UNC darabszám"/>
		<PieceIndicator piece={pieceEf} tooltip="EF darabszám"/>
		<PieceIndicator piece={pieceVf} tooltip="VF darabszám"/>
	</div>
}

const ListPage = ({match, history, location}) => {
	let params = history.location ? new URLSearchParams(history.location.search) : ''
	let searchTextParam = params.get('searchText')
	let penznem = match.params.penznem ? match.params.penznem.toUpperCase() : null
	let sortValues = [
		{label: 'Kibocsátás szerint növekvő', value: 'NORMAL#NOVEKVO'},
		{label: 'Kibocsátás szerint csökkenő', value: 'NORMAL#CSOKKENO'},
		{label: 'Névérték szerint növekvő', value: 'NEVERTEK#NOVEKVO'},
		{label: 'Névérték szerint csökkenő', value: 'NEVERTEK#CSOKKENO'}
	]
	useEffect(() => {
		preventImageDownload()
	})
	const userContext = useContext(UserContext)
	const bankjegyContext = useContext(BankjegyContext)
	let [selectedSortBy, setSelectedSortBy] = useState(bankjegyContext.getSortSorozat())
	let [bankjegyList, setBankjegyList] = useState([])

	const queryFindBankjegy = useQuery(FIND_BANKJEGY, {
		variables: {
			sorozatId: match.params.sorozatId,
			searchText: searchTextParam,
			sorrend: bankjegyContext.getSortSorozat().split('#')[0],
			sorrendIrany: bankjegyContext.getSortSorozat().split('#')[1]
		},
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			if (data.findBankjegy) {
				setBankjegyList(data.findBankjegy)
			}
			setSelectedSortBy(bankjegyContext.getSortSorozat())
		}
	})
	useEffect(() => {
		let initSortBy = bankjegyContext.getSortSorozat()
		setSelectedSortBy(initSortBy)
		queryFindBankjegy.refetch({
			sorozatId: match.params.sorozatId,
			searchText: searchTextParam,
			sorrend: initSortBy.split('#')[0],
			sorrendIrany: initSortBy.split('#')[1]
		})?.then(result => {
			setBankjegyList(result.data.findBankjegy)
		})
		// eslint-disable-next-line
	}, [bankjegyContext.sortSorozat])

	const [deleteBankjegy] = useMutation(DELETE_BANKJEGY, {
		variables: {
			'bankjegyId': bankjegyContext.selectedBankjegyId
		},
		onCompleted() {
			setModalIsOpen(false)
			userContext.growl({severity: 'info', summary: 'Bankjegy törlése sikeresen megtörtént!'})
		},
		onError(error) {
			userContext.growl({severity: 'error', summary: 'Nem sikerült a bankjegy törlése!', detail: JSON.stringify(error)})
		},
		refetchQueries: [{
			query: FIND_BANKJEGY,
			variables: {
				penznem: penznem,
				sorozatId: match.params.sorozatId,
				searchText: searchTextParam,
				sorrend: selectedSortBy.split('#')[0],
				sorrendIrany: selectedSortBy.split('#')[1]
			}
		}]
	})

	const [modalIsOpen, setModalIsOpen] = useState(false)

	const messageRef = useRef(null)
	let newLinkTo = {pathname: `/bankjegy_szerkesztes/${match.params.sorozatId}`}
	let clickLink = () => {
		bankjegyContext.setBankjegyList(queryFindBankjegy?.data?.findBankjegy || [])
		bankjegyContext.setSelectedBankjegyId(null)
	}
	const viewport = useViewport()
	return (
		<React.Fragment>
			{['md', 'lg', 'xl'].includes(viewport) && <UserHeaderPanel theme="white" className="py-2"/>}
			<div className="list-page-content m-3">
				<Breadcrumb withLink={false}>
					{userContext.loggedInUser.szerep === 'ADMIN' && <Link className="brown-button" to={newLinkTo} onClick={clickLink}>
						<div className="d-inline-flex align-items-center" title="Új bankjegy felvétele">
							<img src={imgNew16} alt="new-bankjegy"/>
						</div>
					</Link>}
				</Breadcrumb>
				<ScrScrollUpButton/>
				{queryFindBankjegy.loading && <ProgressBar className="mt-3" mode="indeterminate"/>}
				{createErrorMessage(queryFindBankjegy.error, 'Hiba a lekérdezés során!', messageRef, 20000)}
				{queryFindBankjegy.error && <Messages className="mt-3" ref={messageRef}/>}
				{bankjegyList &&
				<>
					<div className="d-flex justify-content-between">
						<div className="mb-3 col-auto">
							Találatok száma: {bankjegyList.length}
						</div>
						<div className="col-auto">
							<Dropdown
								className="pf-combo"
								value={selectedSortBy}
								onChange={(e) => {
									if (e.target?.value) {
										setSelectedSortBy(e.target.value)
										bankjegyContext.setSortSorozat(e.target.value)
									}
								}}
								options={sortValues}
							/>
						</div>
					</div>

					<BankjegyList
						bankjegyContext={bankjegyContext}
						values={bankjegyList}
						location={location}
						modalIsOpen={modalIsOpen}
						setModalIsOpen={setModalIsOpen}
						deleteBankjegy={deleteBankjegy}/>
				</>}
			</div>
		</React.Fragment>
	)
}

export default withRouter(ListPage)
