import React, {useContext, useMemo, useState} from 'react'
import {Button} from 'primereact/button'
import {Formik} from 'formik'
import {UserContext} from '../../components/UserContext'
import * as Yup from 'yup'
import {setLocale} from 'yup'
import gql from 'graphql-tag'
import {useMutation, useQuery} from '@apollo/client'
import {Prompt, useHistory} from 'react-router'
import AutoCompleteEditor from '../../components/editors/AutoCompleteEditor'
import CheckboxEditor from '../../components/editors/CheckboxEditor'
import HtmlEditor from '../../components/editors/HtmlEditor'
import TextEditor from '../../components/editors/TextEditor'
import {createErrorGrowl} from '../user/LoginPage'
import $ from 'jquery'
import AdminPanel from '../../layout/AdminPanel'
import {Dialog} from 'primereact/dialog'

const LIST_FELHASZNALOK = gql`
    query ListFelhasznalok {
        listFelhasznalok {
            id
            email
            nev
            szerep
        }
    }
`
const SEND_EMAIL = gql`
    mutation SendEmail($emailKuldes: EmailKuldes!) {
        sendEmail(emailKuldes: $emailKuldes)
    }
`

const EmailPage = () => {
	const userContext = useContext(UserContext)
	const history = useHistory()

	const initialValues = {
		receivers: [],
		allReceivers: false,
		subject: '',
		body: null
	}

	setLocale({
		mixed: {
			required: 'A mező kitöltése kötelező!'
		}
	})

	const emailValidationSchema = Yup.object().shape({
		receivers: Yup.mixed().test('receiversTest', 'Címzett megadása kötelező vagy válassza az "Üzenetküldés minden felhasználó részére" funkciót!', function (value) {
			const {allReceivers} = this.parent
			if (!allReceivers) {
				return value != null && value.length > 0
			}
			return true
		}),
		subject: Yup.mixed().required(),
		body: Yup.mixed().required()
	})

	let [possibleFelhasznalok, setPossibleFelhasznalok] = useState([])

	const getDisplayString = (value) => {
		return value.nev + ' (' + value.email + ')'
	}

	const queryMethod = (e, qValue) => {
		return qValue.nev.toLowerCase().startsWith(e.query.toLowerCase()) || qValue.email.toLowerCase().startsWith(e.query.toLowerCase())
	}

	const listFelhasznalokQuery = useQuery(LIST_FELHASZNALOK)

	const [sendEmailMutation] = useMutation(SEND_EMAIL, {
		onCompleted() {
			userContext.growl({severity: 'info', summary: 'Az üzenet elküldése sikeresen megtörtént!'})
		},
		onError(error) {
			createErrorGrowl(error, 'Nem sikerült az üzenetet elküldeni!', userContext, 20000)
		}
	})

	useMemo(() => {
		let possibleFelhasznalokRes = []
		if (listFelhasznalokQuery.data && listFelhasznalokQuery.data.listFelhasznalok) {
			Object.entries(listFelhasznalokQuery.data.listFelhasznalok).map((value, idx) => {
				let label = getDisplayString(value[1])
				return possibleFelhasznalokRes.push({key: value[1].id, label: label, value: value[1]})
			})
			possibleFelhasznalokRes.sort((o1, o2) => (
				o1.label[0].localeCompare(o2.label, 'hu')
			))
			possibleFelhasznalokRes = [{key: 0, label: 'Válasszon!', value: null}, ...possibleFelhasznalokRes]
			setPossibleFelhasznalok(possibleFelhasznalokRes)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listFelhasznalokQuery])

	const submit = async () => {
		setShowModel(true)
	}

	const SendEmailModal = ({values, resetForm}) => {
		return <Dialog
			header="Küldés"
			visible={showModel}
			onHide={onModalClose}>
			<div className="">
				<div>
					Biztos, hogy el akarja küldeni az üzenetet? <br/>
				</div>
				<div className="buttons mt-3">
					<Button label="Igen"
						className='green-button'
						type="button"
						onClick={e => {
							sendEmailMutation({
								variables: {
									emailKuldes: {
										cimzettek: Array.isArray(values.receivers) ? values.receivers.map(f => f.email) : [],
										mindenkinek: values.allReceivers,
										targy: values.subject,
										uzenet: values.body
									}
								}
							}).then(() => {
									$('.p-autocomplete-token').remove()
									resetForm({})
								}
							)
							setShowModel(false)
						}}/>
					<Button label="Nem"
						className="brown-button"
						type="button"
						onClick={e => {
							setShowModel(false)
						}}/>
				</div>
			</div>
		</Dialog>
	}

	const htmlEditorHeader = (
		<>
			{/*configuration: https://quilljs.com/docs/modules/toolbar/#container*/}
			<span className="ql-formats">
				<button className="ql-bold" aria-label="Bold"></button>
				<button className="ql-italic" aria-label="Italic"></button>
				<button className="ql-underline" aria-label="Underline"></button>
			</span>
			<span className="ql-formats">
				<select className="ql-size" defaultValue={''}>
					<option value="small"></option>
					<option value={''}></option>
					<option value="large"></option>
					<option value="huge"></option>
				</select>
			</span>
			<span className="ql-formats">
				<select className="ql-align" defaultValue={''}>
					<option value="center"></option>
					<option value={''}></option>
					<option value="justify"></option>
					<option value="right"></option>
				</select>
			</span>
			<span className="ql-formats">
				<button className="ql-list" aria-label="Unordered" value="bullet"></button>
				<button className="ql-list" aria-label="Ordered" value="ordered"></button>
			</span>
			{/*<span className="ql-formats">*/}
			{/*	<button className="ql-link" aria-label="Insdrt Link"></button>*/}
			{/*<button className="ql-script" value="sub"></button>*/}
			{/*<button className="ql-script" value="super"></button>*/}
			{/*</span>*/}
		</>
	)

	const [showModel, setShowModel] = useState(false)
	const onModalClose = () => {
		setShowModel(false)
	}

	return <AdminPanel label="Üzenetküldés" stretch>
		{userContext.loggedInUser.szerep !== 'ADMIN' ?
			<div className="alert alert-warning mx-3" role="alert">Nincs megfelelő jogosultsága!</div> :
			<Formik
				initialValues={initialValues}
				validationSchema={emailValidationSchema}
				onSubmit={submit}
			>
				{({handleSubmit, values, resetForm, setFieldValue}) => {
					return <form className="email-form"
						onSubmit={handleSubmit}
					>
						<Prompt
							when={JSON.stringify(values) !== JSON.stringify(initialValues)}
							message="El nem mentett adatok vannak az oldalon. Biztosan elhagyja az oldalt?"
						/>
						<SendEmailModal values={values} resetForm={resetForm}/>
						<div className="mb-3">
							<AutoCompleteEditor
								disabled={values.allReceivers === true}
								fieldLabel="Címzett"
								placeholder="Címzett"
								fieldName="receivers"
								possibleValues={possibleFelhasznalok}
								getDisplayString={getDisplayString}
								queryMethod={queryMethod}
								multiple
								dropdown
								required={values.allReceivers !== true}
							/>
						</div>
						<div className="mb-3">
							<CheckboxEditor fieldLabel="Üzenetküldés minden felhasználó részére" fieldName="allReceivers" onChange={
								(value) => {
									setFieldValue('receivers', '')
								}
							}/>
						</div>
						<div className="mb-3">
							<TextEditor fieldLabel="Tárgy" fieldName="subject" required placeholder="Tárgy"/>
						</div>
						<div className="mb-3">
							<HtmlEditor fieldLabel="Üzenet" placeholder="Üzenet" fieldName="body" header={htmlEditorHeader}
								required/>
						</div>
						<div className="buttons">
							<Button
								className="green-button mr-1"
								label="Küldés"
								type="submit"/>
							<Button
								onClick={(e) => {
									history.push('/')
								}}
								className="brown-button"
								label="Mégsem"
								type="button"/>
						</div>
					</form>
				}}
			</Formik>
		}
	</AdminPanel>
}

export default EmailPage
