import React, {useContext, useEffect, useState} from 'react'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/client'
import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import {ProgressBar} from 'primereact/progressbar'
import {getEnumValue} from '../EnumUtils'
import {formatDateTime, formatNumber} from '../Common'
import TextEditor from '../../components/editors/TextEditor'
import ComboEnumEditor from '../../components/editors/ComboEnumEditor'
import {Button} from 'primereact/button'
import {Form, Formik} from 'formik'
import MultiEditor from '../../components/editors/MultiEditor'
import {GET_BANKJEGY} from '../main/BankjegyPage'
import {withRouter} from 'react-router-dom'
import imgClean from '../../images/clean-w-16.png'
import imgView from '../../images/view-w-16.png'
import Carousel from 'react-images'
import moment from 'moment'
import DateEditor from '../../components/editors/DateEditor'
import {UserContext} from '../../components/UserContext'
import * as Yup from 'yup'
import {setLocale} from 'yup'
import AutoCompleteEditor from '../../components/editors/AutoCompleteEditor'
import AdminPanel from '../../layout/AdminPanel'
import {Dialog} from 'primereact/dialog'

const LIST_FELHASZNALOK = gql`
    query ListFelhasznalok {
        listFelhasznalok {
            id
            email
            nev
            szerep
        }
    }
`

const FIND_NAPLO = gql`
    query FindNaplo($esemeny: Esemeny, $leiras: String, $letrehozasIdejetol: DateTime, $letrehozasIdejeig: DateTime, $egyedTipus: EgyedTipus, $egyedId:Long, $felhasznaloId:Long) {
        findNaplo(esemeny: $esemeny, leiras: $leiras, letrehozasIdejetol: $letrehozasIdejetol, letrehozasIdejeig: $letrehozasIdejeig, egyedTipus: $egyedTipus, egyedId: $egyedId, felhasznaloId: $felhasznaloId) {
            maxResults
            count
            result {
                id
                esemeny
                leiras
                letrehozasIdeje
                egyedTipus
                egyedId
                felhasznaloId
                felhasznaloNev
                felhasznaloEmail
                felhasznaloTelefonszam
                regiErtek
                ujErtek
            }
        }
    }
`

const LogsPage = withRouter(({history}) => {
	const [first, setFirst] = useState(null)
	const [images, setImages] = useState([])

	const getBankjegyQuery = useQuery(GET_BANKJEGY)
	const userContext = useContext(UserContext)
	let [possibleFelhasznalok, setPossibleFelhasznalok] = useState([])
	useEffect(() => {
	}, [possibleFelhasznalok])
	useQuery(LIST_FELHASZNALOK, {
		fetchPolicy: 'network-only', onCompleted(data) {
			Object.entries(data.listFelhasznalok).map((value, idx) => {
				let label = getDisplayString(value[1])
				return possibleFelhasznalok.push({key: value[1].id, label: label, value: value[1]})
			})
			possibleFelhasznalok.sort((o1, o2) => (
				o1.label.localeCompare(o2.label, 'hu')
			))
			possibleFelhasznalok = [{key: 0, label: 'Válasszon!', value: null}, ...possibleFelhasznalok]
			setPossibleFelhasznalok(possibleFelhasznalok)
		}
	})
	const queryMethod = (e, qValue) => {
		return qValue.nev.toLowerCase().startsWith(e.query.toLowerCase()) || qValue.email.toLowerCase().startsWith(e.query.toLowerCase())
	}
	const getDisplayString = (value) => {
		return value.nev + ' (' + getEnumValue('Szerep', value.szerep) + ' - ' + value.email + ')'
	}

	const esemenyTemplate = (rowData, column) => {
		return getEnumValue('Esemeny', rowData.esemeny)
	}
	const egyedTipusTemplate = (rowData, column) => {
		return getEnumValue('EgyedTipus', rowData.egyedTipus)
	}
	const letrehozasIdejeTemplate = (rowData, column) => {
		return formatDateTime(rowData.letrehozasIdeje)
	}
	const felhasznaloTemplate = (rowData, column) => {
		return <>
			{rowData.felhasznaloNev}<br/>
			{rowData.felhasznaloEmail}<br/>
			{rowData.felhasznaloTelefonszam}<br/>
		</>
	}

	const displayValue = (fieldName, val) => {
		if (isPrice(fieldName)) {
			return displayPrice(fieldName, val)
		} else if (isImage(fieldName)) {
			return displayImageLink(fieldName, val)
		} else {
			return val
		}
	}

	const isPrice = (fieldName) => {
		const priceFields = ['nevertek', 'ertekUNC', 'ertekEF', 'ertekVF']
		return priceFields.includes(fieldName.split('_')[0])
	}

	const isImage = (fieldName) => {
		const imageFields = ['elooldalEredetiKep', 'elooldalMegjelenoKep', 'elooldalBelyegKep', 'hatoldalEredetiKep', 'hatoldalMegjelenoKep', 'hatoldalBelyegKep']
		return imageFields.includes(fieldName)
	}

	const displayPrice = (fieldName, val) => {
		let formattedValue = val
		let split = val.split(' ')
		if (split.length > 0 && !isNaN(split[0])) {
			const currency = split.length > 1 && split[1]
			formattedValue = `${formatNumber(split[0])} ${currency}`
		}
		return <>
			{formattedValue}
		</>
	}

	const displayImageLink = (fieldName, val) => {
		return (val !== '-' ? <span className="btn-link cursor-pointer" onClick={() => {
			setImages([{src: `/kep/${val}`}])
			onModalOpen()
		}}>{val}</span> : val)
	}

	const OldNewValueDisplay = ({json}) => {
		return <>
			{json && Object.entries(json).map((val, idx) => {
					const enumName = isPrice(val[0]) ? val[0].replace('_', '@') : val[0]
					return <div className="text-nowrap2 mb-2" key={idx}>
						<span>{getEnumValue('Bankjegy', enumName)}: </span> <span className="font-weight-bold">{displayValue(val[0], val[1])}</span>
					</div>
				}
			)}
		</>
	}
	const regiErtekTemplate = (rowData) => {
		let json = rowData.regiErtek && JSON.parse(rowData.regiErtek)
		return <OldNewValueDisplay json={json}/>
	}
	const ujErtekTemplate = (rowData) => {
		let json = rowData.ujErtek && JSON.parse(rowData.ujErtek)
		return <OldNewValueDisplay json={json}/>
	}
	const iconTemplate = (rowData) => {
		return <>
			{rowData.egyedTipus === 'BANKJEGY' && <button type="button" className="brown-button ml-1 cursor-pointer" onClick={() => {
				getBankjegyQuery.refetch({
					id: rowData.egyedId
				}).then((result) => {
					if (result && result.data) {
						let bankjegy = result.data.getBankjegyById
						window.open('/#/bankjegy/' + bankjegy.sorozat.id + '/' + bankjegy.id)
					}
				})
			}}>
				<div className="d-inline-flex align-items-center">
					<img src={imgView} alt="view" title="Bankjegy megtekintése"/>
				</div>
			</button>}
		</>
	}
	let queryListNaplo = useQuery(FIND_NAPLO, {
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true
	})

	const initialValues = {
		egyedTipus: null,
		esemeny: null,
		leiras: '',
		felhasznalo: null,
		letrehozasIdejetol: null,
		letrehozasIdejeig: null
	}

	setLocale({
		mixed: {
			required: 'A mező kitöltése kötelező!'
		}
	})

	Yup.addMethod(Yup.mixed, 'isDate', function (message) {
			return this.test('format', message, value => {
				let isEmpty = value === '' || value === null || value === undefined
				return isEmpty || JSON.stringify(value) === JSON.stringify(new Date(value))
			})
		}
	)

	const logValidationSchema = Yup.object().shape({
		letrehozasIdejetol: Yup.mixed().isDate('Nem megfelelő dátum formátum! (pl.: 2013.01.01.)'),
		letrehozasIdejeig: Yup.mixed().isDate('Nem megfelelő dátum formátum! (pl.: 2013.01.01.)')
	})

	const submit = async (values, {setSubmitting}) => {
		setFirst(null)
		await queryListNaplo.refetch({
			egyedTipus: values.egyedTipus,
			esemeny: values.esemeny,
			felhasznaloId: values.felhasznalo && values.felhasznalo.id,
			leiras: values.leiras,
			letrehozasIdejetol: values.letrehozasIdejetol ? moment(values.letrehozasIdejetol).format() : null,
			letrehozasIdejeig: values.letrehozasIdejeig ? moment(values.letrehozasIdejeig).format() : null
		})
	}

	const [modalIsOpen, setModalIsOpen] = useState(false)
	const onModalOpen = () => {
		setModalIsOpen(true)
	}
	const onModalClose = () => {
		setModalIsOpen(false)
	}

	return <AdminPanel label="Napló" stretch>
		{userContext.loggedInUser.szerep !== 'ADMIN' ?
			<div className="alert alert-warning mx-3" role="alert">Nincs megfelelő jogosultsága!</div> :
			<>
				<Formik
					initialValues={initialValues}
					validationSchema={logValidationSchema}
					onSubmit={submit}
				>
					{({values, resetForm}) => (
						<Form className="search-form">
							<div className="row">
								<div className="col-12 col-sm-6 mb-3">
									<AutoCompleteEditor
										disabled={values.allReceivers === true}
										fieldLabel="Felhasználó"
										placeholder="Felhasználó"
										fieldName="felhasznalo"
										possibleValues={possibleFelhasznalok}
										getDisplayString={getDisplayString}
										queryMethod={queryMethod}
										dropdown
									/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<ComboEnumEditor fieldLabel="Egyed típus" fieldName="egyedTipus" enumClass="EgyedTipus" emptyValue/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<ComboEnumEditor fieldLabel="Esemény" fieldName="esemeny" enumClass="Esemeny" emptyValue/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<TextEditor fieldLabel="Leírás" fieldName="leiras"/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<MultiEditor fieldLabel="Letrehozás ideje" sep="-"
										editor1={
											<DateEditor placeholder="-tól" fieldName="letrehozasIdejetol"/>
										}
										editor2={
											<DateEditor fieldName="letrehozasIdejeig" placeholder="-ig"/>
										}
									></MultiEditor>
								</div>
							</div>
							<div className="buttons mb-3">
								<Button
									className="green-button mr-1"
									label="Keresés"
									type="submit"/>
								<button type="button" className="brown-button ml-1 cursor-pointer"
									onClick={() => {
										resetForm()
									}}
								>
									<div className="d-inline-flex align-items-center">
										<img src={imgClean} alt="clean form" title="Ürlap mezők törlése"/>
									</div>
								</button>
							</div>
						</Form>
					)}
				</Formik>
				{queryListNaplo.loading && <ProgressBar className="mt-3" mode="indeterminate"/>}
				{queryListNaplo.data && queryListNaplo.data.findNaplo &&
					<>
						<Dialog
							visible={modalIsOpen}
							onHide={onModalClose}>
							<div className="d-flex">
								<Carousel views={images}/>
							</div>
						</Dialog>
						<div className="col my-1">
							Találatok száma: {queryListNaplo.data.findNaplo.count}
							{(queryListNaplo.data.findNaplo.maxResults < queryListNaplo.data.findNaplo.count)
								? <><br/>Maximum 1000 találat jeleníthető meg! Kérem szükítse a keresési feltételeket!</> : ''}
						</div>
						<div className="col">
							<DataTable autoLayout={true} paginator rows={15} first={first}
								onPage={(e) => {
									setFirst(e.first)
								}}
								dataKey="id"
								className="w-100" value={queryListNaplo.data ? queryListNaplo.data.findNaplo.result : []}
							>
								<Column body={iconTemplate}/>
								<Column sortable field="egyedTipus" header="Egyed típus" body={egyedTipusTemplate}/>
								<Column sortable field="esemeny" header="Esemény" body={esemenyTemplate}/>
								<Column sortable field="leiras" header="Leírás"/>
								<Column sortable field="letrehozasIdeje" header="Létrehozás ideje" body={letrehozasIdejeTemplate}/>
								<Column sortable field="egyedId" header="Egyed id"/>
								<Column sortable field="felhasznaloId" header="Felhasznaló" body={felhasznaloTemplate}/>
								<Column field="regiErtek" header="Régi érték" body={regiErtekTemplate}/>
								<Column field="ujErtek" header="Új érték" body={ujErtekTemplate}/>
							</DataTable>
						</div>
						<div className="col mt-3">
							<Button
								onClick={() => {
									history.push('/')
								}}
								className="brown-button ml-1"
								label="Bezár"
								type="button"/>
						</div>
					</>
				}
			</>
		}
	</AdminPanel>
})

export default LogsPage
